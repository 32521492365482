import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { HistoryParams } from '@bitboss/bnk-wallet';
import { map } from 'rxjs/operators';
import { ITransaction } from 'src/app/models/ITransactionHistory';
import { BankingService } from 'src/app/services/banking.service';
import { Filter, BankingTxTypeFilterOptions } from './models';
import { CsvService } from 'src/app/services/csv.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

const defaultFilter: Filter = {
  name: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  bankingTxType: 'All',
  table: undefined,
  amountFrom: undefined,
  amountTo: undefined,
  bankingTx: 'All',
  bbenv: environment.production ? 'BUSD' : 'USD',
  accountingTxType: 'all',
}

@Component({
  selector: 'app-transactions-monitoring',
  templateUrl: './transactions-monitoring.component.html',
  styleUrls: ['./transactions-monitoring.component.scss']
})
class TransactionsMonitoringComponent implements OnInit {
  dataSource = new MatTableDataSource<ITransaction>();
  displayedColumns: string[] = ['date', 'accountName', 'bankingType', 'table', 'txType', 'amountUSD'];
  accId: string;
  isLoading = false;

  public pageIndex = 0;
  public historySize = 0;
  public pageSize = 25;
  public filter: Filter = defaultFilter;
  public isFiltersActive = false;
  public filtersOptions = {
    types: ['All', 'Debit', 'Credit'],
    bankingType: ['All', 'Banking', 'Non-banking'],
    bbenv: ['All', 'USD', 'DEVRIVER', 'BUSD', 'RIVER'],
  }

  constructor(
    private router: Router,
    public dialog: MatDialog, 
    public bankingService: BankingService,
    public csv: CsvService,
  ) { }

  private setDefaultDate() {
    this.filter.dateFrom = moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm:ss');
    this.filter.dateTo = moment(new Date().setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');
  }

  private setListeners() {
    document.addEventListener('keypress', e => {
      if (e.code === 'Enter') {
        this.refresh();
      }
    });
  }

  ngOnInit() {
    this.setListeners();
    this.setDefaultDate();
    this.refresh();
  }

  public setFilter(filterKey, filterValue) {
    this.filter[filterKey] = filterValue;
    this.isFiltersActive = !!filterValue;
  }

  private mapBankingTypeFilterToTxType(type: 'All' | 'Banking' | 'Non-banking') {
    switch(type) {
      case 'Non-banking':
        return 'non-banking';
      case 'Banking':
        return 'banking';
      default:
        return;
    }
  }

  private getFilters(): HistoryParams {
    const params: HistoryParams = {
      getCached: true,
      getParsed: true,
      pagination: {
        limit: this.pageSize,
        offset: this.pageIndex * this.pageSize,
      },
      filter: {
        getHidden: false,
        dateFilter: {
          startDate: new Date(this.filter.dateFrom).toISOString(),
	        endDate: new Date(this.filter.dateTo).toISOString(),
        },
        userName: this.filter.name,
        bankingTx: this.mapBankingTypeFilterToTxType(this.filter.bankingTx),
        accountingTxType: this.filter.accountingTxType !== 'all' ? this.filter.accountingTxType : undefined,
        excludeZeroAmountTx: true,
      },
    };

    if (this.filter.amountFrom || this.filter.amountTo) {
      params.filter.amount = {
        min: (this.filter.amountFrom || 0) && +this.filter.amountFrom,
        max: this.filter.amountTo && +this.filter.amountTo,
      }
    }

    return params;
  }

  refresh() {
    this.isLoading = true;
    this.fetchTxs(this.getFilters());
  }

  private fetchTxs(params: HistoryParams) {
    this.bankingService.getTransactionHistory(params, this.filter.bbenv)
      .subscribe(res => {
        this.updateData(res.data);
        this.historySize = res.total;
        this.isLoading = false;
      },
      _ => {
        this.isLoading = false;
      });
  }

  private updateData(data: ITransaction[]) {
    this.dataSource.data = data;
  }

  public onNameClick(acctId: string) {
    this.router.navigate(['/player-management'], { queryParams: { acctId } });
  }

  public clearFilers() {
    this.filter.amountFrom = 0;
    this.filter.name = undefined;
    this.filter.dateFrom = undefined;
    this.filter.dateTo = undefined;
    this.filter.bankingTxType = 'All';
    this.filter.table = undefined;
    this.filter.amountFrom = undefined;
    this.filter.amountTo = undefined;
    this.filter.bankingTx = 'All';
    this.filter.bbenv = environment.production ? 'BUSD' : 'USD';
    this.filter.accountingTxType = 'all';
    this.setDefaultDate();
    this.isFiltersActive = false;
    this.refresh();
  }

  public handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.refresh();
  }

  public get filtersChanged() {
    return this.filter;
  }

  private fetchCSVData() {
    const filters = this.getFilters();
    
    // override pagination no receive all txs instead of a page
    filters.pagination = {
      limit: 0,
      offset: 0,
    };

    this.isLoading = true;
    return this.bankingService.getTransactionHistory(filters, this.filter.bbenv)
      .pipe(map((txs: any) => {
        this.isLoading = false;
        return txs.data;
      }));
  }

  public generateReport() {
    this.fetchCSVData().subscribe(txs => {
      const fileName = `report_${this.filter.dateFrom}-${this.filter.dateTo}`;
      this.csv.txsToCSV(txs, this.displayedColumns, fileName);
    }, _ => this.isLoading = false);
  }
}

export { TransactionsMonitoringComponent };
