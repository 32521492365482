import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ILog } from 'src/app/models/ILog';

@Component({
  selector: 'app-error-details',
  templateUrl: './error-details.component.html',
  styleUrls: ['./error-details.component.scss']
})
export class ErrorDetailsComponent {

  constructor(
    public dialogRef: MatDialogRef<ErrorDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILog) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public formatWithTime(date: Date) {
    return moment(date).format('MM/DD/YYYY HH:mm:ss');
  }

  get message() {
    return JSON.parse(this.data.message);
  }
}
