import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HistoryParams } from '@bitboss/bnk-wallet';
import { ITransaction } from '../models/ITransactionHistory';
import { Bbenv } from '../pages/transactions-monitoring/models';
import { map } from 'rxjs/operators/map';

export interface IHistoryResponse {
  data: ITransaction[];
  limit: number;
  offset: number;
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class BankingService {
  constructor(private http: HttpClient) { }

  public getTransactionHistory(params: HistoryParams, bbenv: Bbenv): Observable<IHistoryResponse> {
    return this.http.post<any>(`${environment.baseUrl}/banking/transactions`, { ...params }, bbenv !== 'All' ? { headers: { bbenv }} : undefined)
      .pipe(map(
        res => ({
          ...res,
          data: res.data?.filter(tx => !tx.isHidden)
        })
      ))
  }
}
