import { Component, OnInit, OnDestroy } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { routes } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { fromEvent, Observable, Subscription } from "rxjs";
import { Roles } from 'src/app/constants/roles';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab?: string;
    type?: string;
    icontype: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
      path: '/support',
      title: 'Support', 
      type: 'link' , 
      icontype: 'account_balance_wallet'
    },
    {
        path: '/user-management',
        title: "Users", 
        type: 'sub',
        icontype: "dashboard",
        collapse: 'user-management',
        children: [
            { path: 'users', title: 'User Management', icontype: 'dashboard' }, 
            { path: 'pending', title: 'Pending Users', icontype: 'dashboard'}, 
        ]
    },
    {
        path: '/casino-management',
        title: 'Casino Management',
        type: 'link',
        icontype: 'dashboard'
    },
    {
        path: '/player-management',
        title: 'Player Management',
        type: 'link',
        icontype: 'dashboard'
    },
    {
        path: '/transactions-monitoring',
        title: 'Transaction Monitoring',
        type: 'link',
        icontype: 'paid'
    },
    {
        path: '/error-handling',
        title: 'Error Handling',
        type: 'link',
        icontype: 'dashboard'
    }
    // {
    //     path: '#components',
    //     title: 'Components',
    //     type: 'sub',
    //     icontype: 'apps',
    //     collapse: 'components',
    //     children: [
    //         {path: 'buttons', title: 'Buttons', ab:'B'},
    //         {path: 'grid', title: 'Grid System', ab:'GS'},
    //         {path: 'panels', title: 'Panels', ab:'P'},
    //         {path: 'sweet-alert', title: 'Sweet Alert', ab:'SA'},
    //         {path: 'notifications', title: 'Notifications', ab:'N'},
    //         {path: 'icons', title: 'Icons', ab:'I'},
    //         {path: 'typography', title: 'Typography', ab:'T'}
    //     ]
    // },
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit, OnDestroy {
    private resizeObservable$: Observable<Event>
    private resizeSubscription$: Subscription
    public menuItems: RouteInfo[];
    public username: string;
    public isMobile: boolean = window.innerWidth > 991 ? false : true; 

    constructor(
      private authenticationService: AuthenticationService, 
      ) {}

    ngOnInit() {  
        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
          this.isMobile = window.innerWidth > 991 ? false : true;
        });

        console.log(this.authenticationService.userValue.username);
        
        this.username = this.authenticationService.userValue.username;
        const availableRoutes = routes
            .filter(r => !r.data || !r.data.role || this.authenticationService.userValue.roles.some((userRole: Roles) => r.data.role?.includes(userRole)))
            .map(r => '/' + r.path);
        console.log('availableRoutes', availableRoutes);
        this.menuItems = ROUTES.filter(menuItem => availableRoutes.includes(menuItem.path));


        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }

    ngOnDestroy() {
        this.resizeSubscription$.unsubscribe()
    } 

    hideLayer () {
        const layer = document.querySelector('.close-layer'); 
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];

        const body = document.getElementsByTagName('body')[0];
        if(!layer) {
          console.log('can not hide component!');
          
        }
        body.classList.remove('nav-open');

        layer.classList.remove('visible');
        setTimeout(function() {
            layer.remove();
            $toggle.classList.remove('toggled');
        }, 400);
    }

    onExit() {
        this.hideLayer(); 
        this.authenticationService.logout();
    }
    // It must be refactored
    ps: any;
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
