import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SupportService } from 'src/app/services/support.service';
import { ToastrService } from "ngx-toastr";
import { IWalletsData } from 'src/app/models/IWalletsInfo';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  // filters
  from = moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm:ss');
  to = moment(new Date().setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');

  // data
  onboardings: number;
  kyc: {
    inProgress: number;
    failed: number;
    successful: number;
  };
  wallets: {
    numberOfActiveWallets: number;
    numberOfTotalWallets: number;
    availableWalletBalance: number;
    walletsInfo: IWalletsData;
  };

  edges: {
    totalAvailable: number; 
    available: number;
  }

  constructor(
    private supportService: SupportService,
    private toastr: ToastrService ) {
    this.onboardings = 0;
    this.kyc = {
      inProgress: 0,
      failed: 0,
      successful: 0
    };
    this.edges = {
      available: 0, 
      totalAvailable: 0,
    }
  }

  ngOnInit(): void {
    this.refresh();
    this.getWalletsInfo();
    this.getEdges();
  }

  public onFromDate(date): void {
    this.from = date;
    this.refresh();
  }

  public onToDate(date): void {
    this.to = date;
    this.refresh();
  }

  refresh() {
    this.supportService.getNewUsersInfo(this.from, this.to).subscribe((r) =>{
      this.onboardings = r.onboardings;
      this.kyc = r.kyc;
    });
  }

  getWalletsInfo() {
    this.supportService.getWalletsInfo().subscribe((r) =>{
      this.wallets = r.wallets;
    });
  }

  get walletInfoCasinoData() {
    return this.wallets?.walletsInfo?.casinos;
  }

  get walletInfoFeeNode() {
    return this.wallets?.walletsInfo?.feenode;
  }

  get walletInfoFloatNode() {
    return this.wallets?.walletsInfo?.floatnode;
  }

  get walletInfoFundingNode() {
    return this.wallets?.walletsInfo?.fundingnode;
  }

  private getEdges() {
    this.supportService.getEdges().subscribe((res) => {
      if (res) {
        
        this.edges = {
          ...this.edges, 
          totalAvailable: res.state?.totalAvailable, 
          available: res.state?.available,
        }
      }
    }, err => {
      this.toastr.error("Cannot get Edges");
    })
  }

  reloadBankConfig() {
    this.supportService.reloadBankingConfig().subscribe((r) => {
      if(r.result) {
        this.toastr.info('Reload request submitted to Banking API', 'Success');
      } else {
        this.toastr.info('Reload request was not successfully submitted to Banking API', 'Error');
      }
    });
  }

  rebuildTxHistoryCache() {
    this.supportService.rebuildTxHistoryCache().subscribe((r) => {
      if(r.result) {
        this.toastr.info('Rebuild txs cache', 'Success');
      } else {
        this.toastr.info('Rebuild txs cache', 'Error');
      }
    });
  }

  reSyncTxHistoryCache() {
    this.supportService.reSyncTxHistoryCache().subscribe((r) => {
      if(r.result) {
        this.toastr.info('Re-sync txs cache', 'Success');
      } else {
        this.toastr.info('Re-sync txs cache', 'Error');
      }
    });
  }
}
