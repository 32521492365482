import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './helpers/auth.guard';
import { Roles } from './constants/roles';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { CasinoMappingsComponent } from './pages/casino-mappings/casino-mappings.component';
import { ErrorHandlingComponent } from './pages/error-handling/error-handling.component';
import { PlayerManagementComponent } from './pages/player-management/player-management.component';
import { SupportComponent } from './pages/support/support.component';
import { AdminRegisterComponent } from './pages/admin-register/admin-register.component';
import { TransactionsMonitoringComponent } from './pages/transactions-monitoring/transactions-monitoring.component';

export const routes: Routes = [
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.CasinoAdmin] }
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    component: LoginComponent
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.UserAdmin] }
  },
  {
    path: 'user-management/users',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.UserAdmin] }
  },
  {
    path: 'user-management/pending',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.UserAdmin] }
  },
  {
    path: 'player-management',
    component: PlayerManagementComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.CasinoAdmin, Roles.BankOfficer] }
  },
  {
    path: 'casino-management',
    component: CasinoMappingsComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.CasinoAdmin] }
  },
  {
    path: 'error-handling',
    component: ErrorHandlingComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.CasinoAdmin] }
  },
  {
    path: 'admin-register',
    component: AdminRegisterComponent,
  },
  {
    path: 'transactions-monitoring',
    component: TransactionsMonitoringComponent,
    canActivate: [AuthGuard],
    data: { role: [Roles.BankOfficer] }
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
