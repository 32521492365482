import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Roles } from 'src/app/constants/roles';
import { User } from 'src/app/models/User';
import { customEmailValidator } from 'src/app/helpers/customEmailValidator';

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.scss']
})
export class EditUserFormComponent {
  userForm: FormGroup;
  roleList = Object.values(Roles);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditUserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { create: true, mapping: User}
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      username: [ this.data?.mapping?.username || '', [Validators.required, Validators.email, customEmailValidator()] ],
      roles: this.formBuilder.array([], [Validators.required])
    });

    if (!this.data.create) {
      const checkArray: FormArray = this.userForm.get('roles') as FormArray;
      this.data.mapping.roles.forEach(r => {
        checkArray.push(new FormControl(r));
      })
    }
  }

  isChecked(role) {
    return this.userForm.get('roles').value.includes(role)
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.userForm.get('roles') as FormArray;
    if (e.checked) {
      checkArray.push(new FormControl(e.source.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.source.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onSubmit() {
    if (this.userForm.invalid) {
      return;
    }
    this.dialogRef.close(this.userForm.value);
    return;
  }

  close() {
    this.dialogRef.close();
  } 
}
