import { APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigLoader } from './config-loader';
import { ConfigService } from './services/config.service';
import { LoginComponent } from './pages/login/login.component';
import { EditMappingComponent } from './components/edit-mapping/edit-mapping.component';
import { EditUserFormComponent } from './pages/user-management/components/edit-user-form/edit-user-form.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { CasinoMappingsComponent } from './pages/casino-mappings/casino-mappings.component';
import { PlayerManagementComponent } from './pages/player-management/player-management.component';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { ErrorHandlingComponent } from './pages/error-handling/error-handling.component';
import { ErrorDetailsComponent } from './components/error-details/error-details.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { SupportComponent } from './pages/support/support.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PlayerDetailsComponent } from './pages/player-management/components/player-details/player-details.component';
import { ToastrModule } from 'ngx-toastr';
import { AdminRegisterComponent } from './pages/admin-register/admin-register.component';
import { TransactionsMonitoringComponent } from './pages/transactions-monitoring/transactions-monitoring.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserManagementComponent,
    CasinoMappingsComponent,
    ErrorHandlingComponent,
    EditMappingComponent,
    ErrorDetailsComponent,
    ConfirmDeleteComponent,
    EditUserFormComponent,
    PlayerManagementComponent,
    SupportComponent,
    LoaderComponent,
    PlayerDetailsComponent,
    AdminRegisterComponent,
    TransactionsMonitoringComponent,
  ],
  entryComponents: [EditMappingComponent, ConfirmDeleteComponent, EditUserFormComponent, ErrorDetailsComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    MatDialogModule, MatSidenavModule, MatCardModule, MatProgressSpinnerModule, MatButtonModule,
    MatIconModule, MatToolbarModule, MatListModule, MatFormFieldModule, MatInputModule,
    MatTableModule, MatPaginatorModule, MatButtonToggleModule, MatCheckboxModule, MatSelectModule,
    NgxJsonViewerModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader.load,
      deps: [
        HttpClient,
        ConfigService
      ],
      multi: true
    },
    DecimalPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
