import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { IPlayer } from 'src/app/models/IPlayer';
import { PlayerManagemetService } from 'src/app/services/player-management.service';
import { PlayerDetailsComponent } from './components/player-details/player-details.component';

@Component({
  selector: 'app-player-management',
  templateUrl: './player-management.component.html',
  styleUrls: ['./player-management.component.scss']
})
class PlayerManagementComponent implements OnInit {
  dataSource = new MatTableDataSource<IPlayer>();
  displayedColumns: string[] = ['createdAt', 'name', 'phone', 'email', 'birthday', 'ssn', 'status', 'isDeleted', 'action'];
  name: string;
  accId: string;

  isLoading: boolean = false; 

  constructor(
    private playerManagementService: PlayerManagemetService,
    private route: ActivatedRoute,
    public dialog: MatDialog, 
  ) { }

  ngOnInit() {
    const acctId = this.route.snapshot.queryParamMap.get('acctId');
    if (acctId) {
      this.name = acctId;
      this.onName(this.name);
    } else {
      this.refresh();
    }
  }

  onName(name: string) {
    this.isLoading = true; 
    const query = `?name=${name}`;
    this.refresh(query);
  }

  refresh(queryString?: string) {
    this.playerManagementService.getPlayers(queryString)
    .pipe(
      catchError((err) => {
        this.isLoading = false;
        return of(null)
      }),
    )
    .subscribe((data) => {
      this.isLoading = false;
      if (!data) {
        return; 
      }
      this.dataSource.data = data;
    });
  }

  public formatWithTime(date: Date) {
    return moment(date).format('MM/DD/YYYY HH:mm:ss');
  }

  public async freeze(accId: string) {
    this.isLoading = true;
    await this.playerManagementService.freeze(accId);
    this.refresh();
  }

  public async unfreeze(accId: string) {
    this.isLoading = true;
    await this.playerManagementService.unfreeze(accId);
    this.refresh();
  }

  public async setAsVip(accId: string) {
    this.isLoading = true;
    await this.playerManagementService.setVip(accId);
    this.refresh();
  } 
  
  public async removeVip(accId: string) {
    this.isLoading = true;
    await this.playerManagementService.removeVip(accId);
    this.refresh();
  }

  showDetails(player: IPlayer): void {
    const data = {
      mapping: player,
      freeze: () => this.freeze(player.accId), 
      unfreeze: () => this.unfreeze(player.accId), 
      setAsVip: () => this.setAsVip(player.accId), 
      removeVip: () => this.removeVip(player.accId), 
    };

    this.dialog.open(PlayerDetailsComponent, {
      width: '500px',
      data,
    });
  }

}

export { PlayerManagementComponent };