import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ITransaction } from '../models/ITransactionHistory';

type FormattedTx = Partial<Omit<ITransaction, 'time'> & { time: string }>;

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  constructor() { }

  public getAbsValue(val: number) {
    return Math.abs(val);
  }

  public getTableName(tx: Partial<ITransaction>) {
    return tx.isFee ? ' ' : (tx.table || ' ');
  }

  public getTxType(tx: Partial<ITransaction>) {
    if (tx.isFee) {
      return 'Fee';
    }

    // check for ACH or debit
    if (tx.isBankingTX) {
      if (tx.bankingType === 'ach') {
        return tx.isPending ? 'ACH Pending' : 'ACH';
      } else {
        return 'Debit Card';
      }
    } else {
      // check for ACH or debit
      const memo = tx.playerMemo || tx.targetMemo;

      if (!memo) {
        return 'unknown';
      }

      const parsed = JSON.parse(memo);

      return parsed.toUser ? 'P2P' : 'Casino';
    }
  }

  public getBankingType(tx: Partial<ITransaction>) {
    return tx.amountUSD > 0 ? 'Credit' : 'Debit';
  }

  public formatWithTime(date: string | number) {
    return moment(date).format('MM/DD/YYYY HH:mm:ss');
  }

  private removeCommas(value: string | number) {
    if (typeof value !== 'string') {
      return value;
    }

    // Replace all commas with spaces
    return value.replace(/,/g, '');
  }

  private formatTxs(txs: Partial<ITransaction>[]): FormattedTx[] {
    return txs.map(tx => ({
      ...tx,
      amountUSD: this.getAbsValue(tx.amountUSD),
      date: this.formatWithTime(tx.date),
      time: this.formatWithTime(tx.time),
      table: this.getTableName(tx),
      txType: this.getTxType(tx),
      bankingType: this.getBankingType(tx),
      userName: tx.userName || ' ',
    }));
  }

  private saveFile(content, fileName = 'report') {
    // const blob = new Blob([content], {type: 'text/csv;charset=utf-8;'});
    // const encodedUri = window.URL.createObjectURL(blob);
    const encodedUri = encodeURI(content);
    const fakeLink = document.createElement('a');
    fakeLink.setAttribute('href', encodedUri);
    fakeLink.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(fakeLink);

    fakeLink.click();
  }

  private txsToCSVData(txs: FormattedTx[], accessors: string[]) {
    const noExtraFields = txs.map((tx: any) => {
      let res = '';
      accessors.forEach((key, i) => {
        const last = i === accessors.length - 1;
        const separator = !last ? ',' : '';
        const value = this.removeCommas(tx[key]);

        res += `${value}${separator}`;
      });
      return [res];
    });

    return noExtraFields.join('\n');
  }

  public txsToCSV(txs: Partial<ITransaction>[], headers: string[], fileName: string) {
    const csvContentBase = 'data:text/csv;charset=utf-8,';
    const tableHeaders = headers.join(',') + '\n';

    const formatted = this.formatTxs(txs);
    const tableRows = this.txsToCSVData(formatted, headers);
    const resultCSV = csvContentBase + tableHeaders + tableRows;

    this.saveFile(resultCSV, fileName);
  }

  private getMemo(tx, isPlayer = true) {
    const memo = isPlayer ? tx.playerMemo : tx.targetMemo;
    
    if (memo && typeof memo === 'string') {
      return JSON.parse(memo);
    }
    
    return memo;
  }

  public getAmountFromMemo(tx): number {
    const memo = this.getMemo(tx, true);
    return memo?.amount;
  }
}
