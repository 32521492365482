export enum Roles {
    CasinoAdmin = 'CasinoAdmin',
    UserAdmin = 'UserAdmin',
    BankOfficer = 'BankOfficer',
}

export const defaultRolePathes = new Map<string | undefined, string>(
    [
        [undefined, '/login'],
        [Roles.CasinoAdmin, '/casino-management'],
        [Roles.UserAdmin, '/user-management'],
        [Roles.BankOfficer, '/transactions-monitoring'],
    ]
);
