import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-admin-register',
  templateUrl: './admin-register.component.html',
  styleUrls: ['./admin-register.component.scss']
})
export class AdminRegisterComponent implements OnInit {
  registerForm: FormGroup;
  jwt: string;
  isPassword = true;
  inputType = 'password';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private zone: NgZone,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required ,Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$/g)]],
      passwordConfirm: ['', Validators.required],
    });

    this.route.queryParams.subscribe(params => {
      this.jwt = params['jwt'];
    });
  }

  get controls() {
    return this.registerForm.controls;
  }

  togglePasswordVisibility() {
    this.isPassword = !this.isPassword;
    if (this.isPassword) {
      this.inputType = 'password';
      return;
    }

    this.inputType = 'text';
  }

  onSubmit() {
    const isPasswordsEqual = this.controls.password.value === this.controls.passwordConfirm.value;

    if (this.registerForm.invalid || !isPasswordsEqual) {
      this.toastr.error('Invalid Password!')
      return;
    }

    this.authService.registerUser(this.controls.password.value, this.jwt).subscribe((res) => {
      this.zone.run(() => {
        this.router.navigate(['login']);
      });
    }, (error) => this.toastr.error(error));
  }

}
