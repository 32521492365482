import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IEdges } from "../models/IEdges";
import { INewUsersInfo } from "../models/INewUsersInfo";
import { IWalletsInfo } from "../models/IWalletsInfo";

@Injectable({providedIn: 'root'})
export class SupportService {

    constructor(private http: HttpClient) {}

    public getNewUsersInfo(from: string, to: string) {
      return this.http.get<INewUsersInfo>(`${environment.baseUrl}/support?from=${+new Date(from)}&to=${+new Date(to)}`);
    }

    public getWalletsInfo() {
      return this.http.get<IWalletsInfo>(`${environment.baseUrl}/support/wallets`);
    }

    public reloadBankingConfig() {
      return this.http.get<any>(`${environment.baseUrl}/support/reloadBankingConfig`);
    }

    public rebuildTxHistoryCache() {
      return this.http.get<any>(`${environment.baseUrl}/support/rebuild-tx-history-cache`);
    }

    public reSyncTxHistoryCache() {
      return this.http.get<any>(`${environment.baseUrl}/support/resync-tx-history-cache`);
    }

    public getEdges() {
      return this.http.get<IEdges>(`${environment.baseUrl}/support/edges`);
    }
}
