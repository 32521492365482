import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CasinoKeyMapping } from '../models/CasinoKeyMapping';

@Injectable({
  providedIn: 'root'
})
export class CasinoMappingService {

  constructor(private http: HttpClient) {}

  public getMappings(limit: number = 50, skip: number = 0) {
    return this.http.get<CasinoKeyMapping[]>(`${environment.baseUrl}/casino?limit=${limit}&skip=${skip}`);
  }

  public delete(id: string) {
    return this.http.delete(`${environment.baseUrl}/casino/${id}`);
  }

  public update(mapping: CasinoKeyMapping) {
    return this.http.put(`${environment.baseUrl}/casino`, mapping);
  }

  public create(mapping: CasinoKeyMapping) {
    return this.http.post(`${environment.baseUrl}/casino`, mapping);
  }
}
