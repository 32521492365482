import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';
import { CasinoMappingService } from 'src/app/services/casino-mapping.service';
import { CasinoKeyMapping } from 'src/app/models/CasinoKeyMapping';
import { EditMappingComponent, FormConfig } from 'src/app/components/edit-mapping/edit-mapping.component';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-casino-mappings',
  templateUrl: './casino-mappings.component.html',
  styleUrls: ['./casino-mappings.component.scss']
})
export class CasinoMappingsComponent implements OnInit {
  mappingsToDisplay = '50';
  dataSource = new MatTableDataSource<CasinoKeyMapping>();
  displayedColumns: string[] = ['name', 'pubKey', 'status', 'edit', 'delete'];

  constructor(
    private casinoMappingService: CasinoMappingService,
    public dialog: MatDialog,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.refresh(parseInt(this.mappingsToDisplay, 10));
  }

  isIssue(date: string) {
    if (!date) return true;
    return new Date(date).getTime() < Date.now() - 5 * 60000;
  }

  openEditDialog(currentMap: CasinoKeyMapping): void {
    const data = new FormConfig('edit', [
      {
        name: '_id',
        title: 'ID',
        value: currentMap._id,
        hidden: true,
      },
      {
        name: 'name',
        title: 'Casino Name',
        value: currentMap.name,
      },
      {
        name: 'pubKey',
        title: 'Public Key',
        value: currentMap.pubKey,
      },
    ]);

    const dialogRef = this.dialog.open(EditMappingComponent, {
      width: '500px',
      data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      console.log('Edit dialog was closed', result.data);
      this.casinoMappingService.update(result.data).subscribe(res => {
        console.log('Update api response', res);
        this.refresh(parseInt(this.mappingsToDisplay, 10));
      }, err => {
        console.error('Error updating player mapping', result, err);
      });
    });
  }

  openCreateDialog(): void {
    const data = new FormConfig('Create', [
      {
        name: 'name',
        title: 'Casino Name',
        value: '',
      },
      {
        name: 'pubKey',
        title: 'Public Key',
        value: '',
      },
    ]);

    const dialogRef = this.dialog.open(EditMappingComponent, {
      width: '500px',
      data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      console.log('Create dialog was closed', result.data);
      this.casinoMappingService.create(result.data).subscribe(res => {
        console.log('Update api response', res);
        this.refresh(parseInt(this.mappingsToDisplay, 10));
      }, err => {
        console.error('Error updating player mapping', result, err);
      });

    });
  }

  openDeleteDialog(currentMap: CasinoKeyMapping) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '375x',
      data: { text: `Remove casino: ${currentMap.name}?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Delete dialog was closed', result);
      this.casinoMappingService.delete(currentMap._id).subscribe(res => {
        console.log('Delete api response', res);
        this.refresh(parseInt(this.mappingsToDisplay, 10));
      }, err => {
        console.error('Error deleting player mapping', result, err);
      });
    });
  }

  changeNumMappings(val) {
    console.log('num mappings changing', val);
    this.mappingsToDisplay = val;
    this.refresh(parseInt(this.mappingsToDisplay, 10));
  }

  refresh(limit: number) {
    this.casinoMappingService.getMappings(limit, 0).subscribe((data: CasinoKeyMapping[]) => {
      this.dataSource.data = data;
    });
  }
}

