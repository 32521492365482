import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { IPlayer } from '../models/IPlayer';
import { IPlayerInfo } from '../models/PlayerInfo';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HistoryParams } from '@bitboss/bnk-wallet';
import { Bbenv } from '../pages/transactions-monitoring/models';
import { IHistoryResponse } from './banking.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerManagemetService {
  constructor(private http: HttpClient) {}

  public getPlayers(query: string) {
    const nameFilter = query ? query : '';
    return this.http.get<IPlayer[]>(`${environment.baseUrl}/player-management/players${nameFilter}`)
      .pipe(map(
        players => players.sort((p1, p2) => new Date(p2.createdAt).getTime() - new Date(p1.createdAt).getTime())
      ));
  }

  public getItem(accId: string) {
    return this.http.get<IPlayerInfo>(`${environment.baseUrl}/player-management/players/${accId}`)
    .pipe(catchError(err => throwError(err)))
    .toPromise();
  }

  public getPlayerHistory(accId: string, params: HistoryParams, bbenv: Bbenv) {
    return this.http.post<IHistoryResponse>(`${environment.baseUrl}/player-management/players/getTransactionHistory/${accId}`, { ...params }, bbenv !== 'All' ? { headers: { bbenv }} : undefined)
      .pipe(
        map(res => res?.data?.filter(tx => !tx.isHidden)),
        catchError(err => throwError(err))
      )
      .toPromise();
  }

  public setVip(accId: string) {
    return this.http.patch(`${environment.baseUrl}/player-management/players/setVip/${accId}`, {}).toPromise(); 
  }

  public removeVip(accId: string) {
    return this.http.patch(`${environment.baseUrl}/player-management/players/removeVip/${accId}`, {}).toPromise(); 
  }

  public freeze(accId: string) {
    return this.http.patch(`${environment.baseUrl}/player-management/players/freeze/${accId}`, {}).toPromise();
  }

  public unfreeze(accId: string) {
    return this.http.patch(`${environment.baseUrl}/player-management/players/unfreeze/${accId}`, {}).toPromise();
  }
}