import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Row {
  name: string;
  title: string;
  value: string;
  hidden?: boolean;
}

export class FormConfig {
  constructor (
    public title: string,
    public rows: Row[],
  ) {}

  get data() {
    const data = {};
    this.rows.forEach(r => {
      data[r.name] = r.value;
    });
    return data;
  }
}

@Component({
  selector: 'app-edit-mapping',
  templateUrl: './edit-mapping.component.html',
  styleUrls: ['./edit-mapping.component.scss']
})
export class EditMappingComponent {
  constructor(
    public dialogRef: MatDialogRef<EditMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormConfig) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
