import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { defaultRolePathes, Roles } from '../constants/roles';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userValue;

        if (user) {
            const roleAllowed = user.roles.some((userRole: Roles) => route.data.role?.includes(userRole));

            if (state.url.startsWith('/login') && user.roles.length || !roleAllowed) {
                this.router.navigate([ defaultRolePathes.get(user.roles[0]) ]);
                return false;
            }

            return true;
        }

        if (state.url.startsWith('/login')) {
            return true;
        }
        
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
