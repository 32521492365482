import { HttpClient } from '@angular/common/http';
import { of, Observable, ObservableInput } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConfigService } from './services/config.service';

export class ConfigLoader {
    public static load(http: HttpClient, config: ConfigService): (() => Promise<boolean>) {
        return (): Promise<boolean> => {
            return new Promise<boolean>((resolve: (a: boolean) => void): void => {
                http.get('assets/config/app-config.json')
                    .pipe(
                        map((x: ConfigService) => {
                            config.baseUrl = x.baseUrl;
                            resolve(true);
                        }),
                        catchError((x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
                            if (x.status !== 404) {
                                resolve(false);
                            }
                            config.baseUrl = 'http://localhost:3000/api';
                            resolve(true);
                            return of({});
                        })
                    ).subscribe();
            });
        };
    }
}
