import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserManagemetService {

  constructor(private http: HttpClient) {}

  public inviteUser(user: User) {
    return this.http.post<User>(`${environment.baseUrl}/user-management/invite-user`, user);
  }

  public getUsers(limit: number = 0, skip: number = 0) {
    return this.http.get<User[]>(`${environment.baseUrl}/user-management/${limit}/${skip}`);
  }

  public update(user: User) {
    return this.http.put(`${environment.baseUrl}/user-management`, user);
  }

  public delete(id: string) {
    return this.http.delete(`${environment.baseUrl}/user-management/${id}`);
  }

  public getPendingUsers(limit: number = 0, skip: number = 0) {
    return this.http.get<User[]>(`${environment.baseUrl}/user-management/pending?skip=${skip}&limit=${limit}`);
  }
}
