import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const customEmailValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const pattern = /\S+@\S+\.\S+/;
    const value = control.value; 

    if (!value) {
      return null;
    }

    return !pattern.test(value) ? { invalidEmail: true } : null;
  }
}