import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';
import { User } from 'src/app/models/User';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserManagemetService } from 'src/app/services/user-management.service';
import { EditUserFormComponent } from './components/edit-user-form/edit-user-form.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
class UserManagementComponent implements OnInit {
  mode = '';
  mappingsToDisplay = '50';
  dataSource = new MatTableDataSource<User>();
  displayedColumns: string[] = ['username', 'roles', 'edit', 'delete'];
  displayedColumnsPending: string[] = ['username', 'roles'];
  currentUserEmail: string = '';

  constructor(
    private userManagementService: UserManagemetService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
  ) {
    const url = this.router.url; 
    this.mode = url.includes('pending') ? 'pending' : '';
  }

  ngOnInit() {
    this.refresh(parseInt(this.mappingsToDisplay, 10));
    const userValue = this.authenticationService.userValue;
    this.currentUserEmail = userValue?.username;
  } 

  changeNumMappings(val) {
    console.log('num requests changing', val);
    this.mappingsToDisplay = val;
    this.refresh(parseInt(this.mappingsToDisplay, 10));
  }

  refresh(limit: number) {
    if (this.mode === 'pending') {
      this.userManagementService.getPendingUsers(limit, 0).subscribe((data) => {
        this.dataSource.data = data;
      });
      return;
    }

    this.userManagementService.getUsers(limit, 0).subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  openCreateDialog(): void {
    const data = {
      create: true,
      mapping: {}
    };

    const dialogRef = this.dialog.open(EditUserFormComponent, {
      width: '500px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result || !result.username || !result.roles.length) return;

      result.username = result.username.trim();

      this.userManagementService.inviteUser(result).subscribe(res => {
        this.toastr.success('Email invite has been sent');
        this.refresh(parseInt(this.mappingsToDisplay, 10));
      }, err => {
        this.toastr.error(err);
        console.error('Error create user', result, err);
      });

    });  
  }

  openEditDialog(user: User): void {
  
    const data = {
      create: false,
      mapping: user,
    };

    const dialogRef = this.dialog.open(EditUserFormComponent, {
      width: '500px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      };
      console.log('Edit dialog was closed', result);
      this.userManagementService.update({ ...result, _id: user._id }).subscribe(res => {
        console.log('Update api response', res);
        this.refresh(parseInt(this.mappingsToDisplay, 10));
      }, err => {
        console.error('Error updating player mapping', result, err);
      });
    });
  }

  openDeleteDialog(user: User) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '375x',
      data: { text: `Delete user with username: ${user.username}?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Delete dialog was closed', result);
      if (result?.delete === true) {
        this.userManagementService.delete(user._id).subscribe(res => {
          console.log('Delete api response', res);
          this.refresh(parseInt(this.mappingsToDisplay, 10));
        }, err => {
          console.error('Error deleting player mapping', result, err);
        });
      }
    });
  }
}

export { EditUserFormComponent, UserManagementComponent };

