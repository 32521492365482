import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { ILog } from '../models/ILog';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private http: HttpClient) {}

  public getLogs(from: string, to: string) {
    return this.http.get<ILog[]>(`${environment.baseUrl}/logger/logs?from=${+new Date(from)}&to=${+new Date(to)}`);
  }
}
