import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { IPlayerDetailsComponentData } from "src/app/models/PlayerDetailsComponentData";
import { IPlayerInfo } from "src/app/models/PlayerInfo";
import { PlayerManagemetService } from "src/app/services/player-management.service";
import { Clipboard } from '@angular/cdk/clipboard';
import { CsvService } from "src/app/services/csv.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Roles } from "src/app/constants/roles";
import { HistoryParams } from "@bitboss/bnk-wallet";
import { ITransaction } from "src/app/models/ITransactionHistory";

@Component({
  selector: "app-player-details",
  templateUrl: "./player-details.component.html",
  styleUrls: ["./player-details.component.scss"],
})
export class PlayerDetailsComponent {
  public isCasinoAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<PlayerDetailsComponent>,
    private playerManagementService: PlayerManagemetService,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private csv: CsvService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: IPlayerDetailsComponentData
  ) {}

  public info: Promise<IPlayerInfo | void>;
  public history: Promise<void | ITransaction[]>;
  player = this.data.mapping;
  errorHistory$: string;

  ngOnInit() {
    this.info = this.playerManagementService
      .getItem(this.data.mapping.accId)
      .catch((err) => {
        this.info = null;
        this.toastr.error(err, "ERROR!");
        this.dialogRef.close();
      });

    const params: HistoryParams = {
      getCached: true,
      getParsed: true,
    };

    this.history = this.playerManagementService
      .getPlayerHistory(this.data.mapping.accId, params, 'All')
      .catch((err) => {
        this.history = null;
        this.errorHistory$ = err;
        this.toastr.error("Can not get history!", "ERROR!");
      });
      
      this.isCasinoAdmin = this.authenticationService.userValue.roles?.includes(Roles.CasinoAdmin);
  }

  getBalance(balance: number | undefined) {
    return isNaN(balance) ? "loading..." : "$" + this.toUSD(balance);
  }

  checkAccountStatus(freezeOnHoldAmount: number): string {
    if (freezeOnHoldAmount && freezeOnHoldAmount > 0) {
      return "Freeze";
    }
    return "Active";
  }

  copyAccID() {
    this.clipboard.copy(this.player.accId);
    this.toastr.info("Acc ID was copied", "Success");
  }

  public toUSD(balance: number) {
    return (balance / 100 || 0).toFixed(2);
  }

  public getDate(unixDate: number) {
    return new Date(unixDate).toLocaleDateString();
  }

  public getTime(unixDate: number) {
    return new Date(unixDate).toLocaleTimeString();
  }

  public getType(tr) {
    if (tr.isBankingTX) {
      switch (tr.bankingType) {
        case 'ach': {
          const bType = 'ACH';
          return `${tr.isPending ? 'Pending ': ''}${bType} ${tr.type}`;
        }
        case 'debit': {
          const bType = 'DEBIT';
          return `${bType} ${tr.type}`;
        }
        default: {
          return tr.type;
        }
      }
    } else {
      return tr.type;
    }
  }

  public async downloadHistory() {
    const history = await this.history;

    if (!history) {
      return;
    }

    const fieldsToInclude = ['time', 'bankingType', 'userName', 'table', 'txType', 'amountUSD'];
    this.csv.txsToCSV(history, fieldsToInclude, this.player.name);
  }
}
