import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private jwtSubject: BehaviorSubject<string>;
    public jwt: Observable<string>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.jwtSubject = new BehaviorSubject<string>(localStorage.getItem('jwt'));
        this.jwt = this.jwtSubject.asObservable();
    }

    public get userValue() {
        return parseJwt(this.jwtSubject.value);
    }

    public get token(): string {
        return this.jwtSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.baseUrl}/auth/login`, { username, password })
            .pipe(map(res => {
                localStorage.setItem('jwt', res.jwt);
                this.jwtSubject.next(res.jwt);
                return res.jwt;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('jwt');
        this.jwtSubject.next(null);
        this.router.navigate(['/login']);
    }

    registerUser(password: string, jwt: string) {
      return this.http.post(`${environment.baseUrl}/auth/register`, { password, jwt });
    }
}

function parseJwt(token) {
    if (!token) return null;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};