import { Component, OnInit } from '@angular/core';
import { ILog } from 'src/app/models/ILog';
import { LoggerService } from 'src/app/services/logger.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorDetailsComponent } from '../../components/error-details/error-details.component';

@Component({
  selector: 'app-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.scss']
})
export class ErrorHandlingComponent implements OnInit {
  dataSource = new MatTableDataSource<ILog>();
  displayedColumns: string[] = ['createdAt', 'service', 'message'];

  // filters
  from = moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm:ss');
  to = moment(new Date().setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');

  constructor(
    private loggerService: LoggerService,
    private dialog: MatDialog,
    ) {}

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.dataSource.data = [];
    this.loggerService.getLogs(this.from, this.to).subscribe((data: ILog[]) => {
      this.dataSource.data = data;
    });
  }
  
  public formatWithTime(date: Date) {
    return moment(date).format('MM/DD/YYYY HH:mm:ss');
  }

  public onFromDate(date: string) {
    this.from = date;
    this.refresh();
  }

  public onToDate(date: string) {
    this.from = date;
    this.refresh();
  }

  openInfo(log: ILog) {
    this.dialog.open(ErrorDetailsComponent, {
      width: '800px',
      data: log,
    });
  }
}
